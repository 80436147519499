import {Box} from '@chakra-ui/react'
import React from 'react'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'
import {Projects, ShampooIntro, Team, Works} from '../components'

const IndexPage = () => {
	return <React.Fragment>
		<ShampooIntro/>
		<Box h={responsiveFontSize(400)}/>
		<Team/>
		<Box h={responsiveFontSize(400)}/>
		<Projects/>
	</React.Fragment>
}

export default IndexPage
